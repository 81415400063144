import image1 from "assests/1.JPG";
import image2 from "assests/2.JPG";
import image3 from "assests/3.JPG";
import image4 from "assests/4.JPG";
import song from "assests/song.mp3"; // Importing the audio file directly

import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowLeft, MusicNotes, PauseCircle } from "@phosphor-icons/react";
import "./style.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import ContentSlider from "./ContentSlider";

const Home = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [image1, image2, image3, image4];
  const [start, setStart] = useState(false);

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const play = () => {
    if (!isPlaying) {
      if (audioRef.current) {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiperInstance.realIndex);
    };

    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperInstance]);

  return (
    <div className="w-[100vw] h-[100dvh] relative">
      <div className="absolute left-0 top-0 w-full h-full bg-[#00000060] z-[100]"></div>
      {start && (
        <div className="absolute text-white left-1/2 -translate-x-1/2 bottom-6 w-full  text-center z-[1000000] flex items-center justify-center gap-x-4">
          <button
            onClick={togglePlay}
            className="absolute left-0 bottom-0 ml-10 "
          >
            {isPlaying ? (
              <PauseCircle tesOff size={24} />
            ) : (
              <MusicNotes size={24} />
            )}
          </button>
          <ArrowLeft size={20} />
          <p>Swipe left</p>
        </div>
      )}
      <Swiper
        onSwiper={setSwiperInstance}
        modules={[Autoplay, EffectFade]}
        speed={2000}
        effect={"fade"}
        loop={true}
        className="heroSlider"
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {data?.map((cont, indx) => (
          <SwiperSlide key={indx} className="">
            <div className="h-full ">
              <div className={`zoom h-full `}>
                <img
                  src={cont}
                  className={`h-full w-full object-cover zoom ${
                    activeIndex === indx ? "zoomed" : ""
                  }`}
                  alt=""
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <ContentSlider start={start} setStart={setStart} onPlaying={play} />

      <audio ref={audioRef} className="hidden">
        <source src={song} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default Home;
